<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
      :load-bg="currentLoadingBg"
    ></Loading>
  </transition>

  <!-- Main content -->
  <ArtistsContent
    :young="false"
    :title="title"
    :city="city"
    :artists="artists"
    :slug="slug"
  >
  </ArtistsContent>
</template>

<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
// import { map, lerp, clamp } from '@/components/tools/utils';
import emitter from '@/components/tools/emitter';
import Loading from '@/components/front/LoadingCity.vue';
import ArtistsContent from '@/components/front/ArtistsContent.vue';

export default {
  data() {
    return {
      title: 'E - XHIBITION',
      slug: '',
      apolloLoad: false,
      imageLoad: false,
      currentLoadingBg: 'bg-citya-day',
      city: [
        {
          City: '',
          SchoolName: '',
          EXhibitionCurator1: '',
          EXhibitionCuratorWebiste1: '',
          EXhibitionCurator2: '',
          EXhibitionCuratorWebiste2: '',
          EXhibitionCurator3: '',
          EXhibitionCuratorWebiste3: '',
          EXhibitionCurator4: '',
          Slug: '',
          artists: [],
        },
      ],
      artists: [],
    };
  },
  computed: {
    load() {
      console.log(`apollo status: ${this.apolloLoad}`);
      return this.apolloLoad && this.imageLoad;
    },
  },
  watch: {
    city: {
      deep: true,
      handler() {
        for (let i = 0; i < this.city[0].artists.length; i += 1) {
          if (!this.city[0].artists[i].YoungCurrent) {
            this.artists.push(this.city[0].artists[i]);
          }
        }
        emitter.emit('emit-city', this.city[0].City);
      },
    },
  },
  apollo: {
    city: {
      query: gql`
        query City($Slug: String!) {
          cities(where: { Slug: $Slug }) {
            Slug
            City
            SchoolName
            EXhibitionCurator1
            EXhibitionCuratorWebiste1
            EXhibitionCurator2
            EXhibitionCuratorWebiste2
            EXhibitionCurator3
            EXhibitionCuratorWebiste3
            EXhibitionCurator4
            artists {
              Name
              Slug
              Thumbnail {
                width
                height
                url
                formats
              }
              YoungCurrent
            }
          }
        }
      `,
      variables() {
        return {
          Slug: this.$route.params.slug,
        };
      },
      update(data) {
        return data.cities;
      },
      error(error) {
        console.error("We've got an error!", error);
      },
      loadingKey: 'loadingQueriesCount',
      // watchLoading will be called whenever the loading state changes
      // eslint-disable-next-line no-unused-vars
      watchLoading(isLoading, countModifier) {
        // isLoading is a boolean
        // countModifier is either 1 or -1

        this.apolloLoad = false;
        if (this.currentLoadingBg !== 'bg-citya-day' && isLoading) {
          this.updateLoadBg();
        }
        if (!isLoading) {
          this.apolloLoad = true;
          console.log('load complete');
        }
      },
    },
  },
  components: {
    Loading,
    ArtistsContent,
  },
  methods: {
    /**
     * Page Load effects
     */
    updateLoadBg() {
      switch (this.$route.params.slug) {
        case 'hong-kong':
          this.currentLoadingBg = 'bg-hongkong-day';
          break;
        case 'macao':
          this.currentLoadingBg = 'bg-macau-day';
          break;
        case 'beijing':
          this.currentLoadingBg = 'bg-beijing-day';
          break;
        case 'san-francisco':
          this.currentLoadingBg = 'bg-sf-day';
          break;
        case 'tallinn':
          this.currentLoadingBg = 'bg-tallinn-day';
          break;
        case 'rome':
          this.currentLoadingBg = 'bg-rome-day';
          break;
        default:
          this.currentLoadingBg = 'bg-citya-day';
          break;
      }
    },
    // imgLoadComplete() {
    //   console.log('image load complete');
    // },
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1.8,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
    },
  },
  mounted() {
    console.clear();
    this.slug = this.$route.params.slug;
    this.updateLoadBg();
    emitter.on('imgLoadComplete', () => {
      console.log('image load complete');
      this.imageLoad = true;
    });
  },
};
</script>
